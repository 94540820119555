import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Constants from "../Utilities/Constants";
import Product from "../Components/Product";
import SideBarGallery from "../Components/SideBar";
import Banners from "../Components/Banners";


const Category = () => {

    const [ products, setProducts] = useState([])
    const { category, subcategory } = useParams();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ title, setTitle ] = useState();

    useEffect(() => {

        console.log('from category',category,subcategory);


        setIsLoading(true);
        fetchTitle();
        fetchProducts();
        setIsLoading(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, subcategory])

    if (isLoading) {
        return <div>Loading...</div>;
    }
    

    const fetchTitle = async () => {
        
        if (category == null)
            return null;
        
        var api =  `${Constants.API_URL_GET_CATEGORY_TITLE}/${category}`;

        try {
            const response = await axios.get(api);
            setTitle(response.data);
        }
        catch(error) {
            console.error(error);
        }
    }

    const fetchProducts = async () => {
        
        // var api = (category)
        //     ? Constants.API_URL_GET_PRODUCTS_BY_CATEGORY
        //     : Constants.API_URL_GET_PRODUCT;
            
        var api =  Constants.API_URL_GET_PRODUCTS_BY_CATEGORY;

        if (category != null) { api = `${api}/${category}` }
        if (subcategory != null) { api = `${api}/${subcategory}` }

        try {
            const response = await axios.get(api);
            setProducts(response.data);
        }
        catch(error) {
            alert("לא נמצאו מוצרים בקטגוריה זו");
        }
    }

    return (
        <>
        <Banners galleryName={category} />
        <div className="container py-4">
            <div className="row">
                <div className="col-md-2 text-center desktop">
                    <div className="d-flex flex-wrap justify-content-around w-100 ">
                        <SideBarGallery />
                    </div>
                </div>
                <div className="col-md-10">
                    <h4 className="text-center p-3">{title}</h4>
                    <div className="d-flex flex-wrap justify-content-around">
                    {products && products.map((product, key) => {
                        return (
                            <Product product={product} key={key} />
                        )
                    })}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}


export default Category;
