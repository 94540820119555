import React from "react";
import { useParams } from "react-router-dom";
import PageContent from "../Components/Content";


const Page = () => {
    const { url } = useParams();

    return (
        <div className="container">
            <PageContent furl={url} />
        </div>
    )
}

export default Page;